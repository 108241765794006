<template>
  <ServiceViewPage
    :service="service"
    :subtitle="
      orderAvailable
        ? service.title
        : $t('Сейчас не работаем, но ждём вас в рабочие дни с {0} до {1}', [
            $options.filters.time(orderTimeFrom),
            $options.filters.time(orderTimeTo),
          ])
    "
  >
    <div v-if="orderAvailable && service.minPrice" class="mt-4 primary--text">
      {{ $t('Минимальный заказ от {0}', [$n(service.minPrice, 'currency')]) }}
    </div>

    <v-form ref="form" class="mt-6" :disabled="saving" @submit.prevent="onSubmit">
      <ProductCatalog
        v-model="cart"
        :categories="categories"
        :products="products"
        :hide-buttons="!orderAvailable"
        :free="service.freeOfCharge"
      />

      <div v-if="orderAvailable" class="pt-8">
        <v-row v-if="!service.freeOfCharge" dense>
          <v-col cols="12">
            <TotalPrice
              :total-price="totalPrice"
              :positions="cart.length"
              :rules="totalPriceRules"
            />
          </v-col>
        </v-row>

        <v-row v-if="hasPickup || hasDelivery" dense>
          <v-col cols="12">
            <v-radio-group
              v-model="form.pickupFromReception"
              :rules="[$rules.required()]"
              row
              hide-details
            >
              <v-radio v-if="hasPickup" :value="true" :label="service.deliveryPickupTitle" />
              <v-radio
                v-if="hasDelivery && isDeliveryTime"
                :value="false"
                :label="service.deliveryCourierTitle"
              />
            </v-radio-group>
          </v-col>
          <template v-if="form.pickupFromReception">
            <v-col v-if="service.showNeedToPack" cols="12">
              <v-checkbox v-model="form.needToPack" :label="$t('Упаковать с собой')" hide-details />
            </v-col>
            <v-col v-if="service.showServedOnTable" cols="12">
              <v-checkbox
                v-model="form.servedOnTable"
                :label="$t('Принести за столик')"
                hide-details
              />
            </v-col>
          </template>
          <v-col v-else-if="form.pickupFromReception === false" cols="12">
            <v-text-field
              v-model="form.deliveryPlace"
              :label="$t('Куда доставить в пределах локации')"
              :rules="[$rules.required()]"
              :hint="$t('Обязательное поле')"
              persistent-hint
              name="deliveryPlace"
            />
          </v-col>
        </v-row>

        <v-row v-if="service.showReadyTime" dense>
          <v-col cols="12">
            <OrderDateInput
              v-model="form.date"
              :service-id="service.id"
              :label="$t('Дата заказа')"
              :rules="[$rules.required()]"
              :hint="$t('Обязательное поле')"
              persistent-hint
              autoselect
            />
          </v-col>
          <v-col cols="12">
            <OrderTimeInput
              v-model="form.time"
              :service="service"
              :date="form.date"
              :is-delivery="form.pickupFromReception === false"
              :label="
                form.pickupFromReception === false ? $t('Время доставки') : $t('Время готовности')
              "
              :rules="[$rules.required()]"
              :hint="$t('Обязательное поле')"
              persistent-hint
            />
          </v-col>
        </v-row>

        <v-row v-if="!userHash" dense>
          <v-col cols="12">
            <v-text-field
              v-model="form.name"
              :rules="[v => !!v || 'Укажите ФИО']"
              label="Фамилия Имя Отчество"
              name="name"
            />
          </v-col>
          <v-col cols="12" md="4">
            <PhoneField v-model="form.phone" label="Телефон" name="phone" type="phone" />
          </v-col>
          <v-col cols="12" md="8">
            <EmailField
              v-model="form.email"
              required
              label="Электронная почта"
              name="email"
              type="email"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="form.comment"
              :label="$t('Комментарий или пожелание')"
              :rows="1"
              auto-grow
            />
          </v-col>
        </v-row>

        <v-row v-if="hasDelivery && !isDeliveryTime" dense>
          <v-col cols="12">
            <TAlert>
              <span>
                {{ $t('Заказ будет ожидать вас {0}. Ждем!', [service.deliveryPickupPlace]) }}
              </span>
              {{
                $t('Вы также можете оформить заказ...', [
                  $options.filters.time(deliveryFrom),
                  $options.filters.time(deliveryTo),
                ])
              }}
            </TAlert>
          </v-col>
        </v-row>

        <v-row v-if="service.deliveryType === 'self_pickup'" dense>
          <v-col cols="12">
            <TAlert>
              <div class="text-body-1">
                {{ $t('Данный заказ можно получить только через мобильное приложение.') }}
                <br />
                {{ service.stock && service.stock.pickupDescription }}
              </div>
            </TAlert>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="d-flex align-center">
            <v-btn
              v-if="showCatalog"
              color="t-grey"
              :disabled="saving"
              outlined
              @click="$emit('back')"
            >
              {{ 'Назад' }}
            </v-btn>
            <v-btn
              class="ml-2"
              color="primary"
              type="submit"
              :disabled="cart.length === 0"
              :loading="saving"
            >
              {{
                totalPrice && !service.freeOfCharge
                  ? $t('Заказать за {0}', [$n(totalPrice, 'currency')])
                  : $t('Заказать')
              }}
            </v-btn>
          </v-col>
          <v-col v cols="12">
            <AcceptTerms :service="service" />
          </v-col>
        </v-row>
      </div>

      <div v-else class="pt-8">
        <TAlert :title="$t('Извините, но сейчас не работаем!')">
          {{
            $t('Заказ доступен в рабочие дни c {0} до {1}', [
              $options.filters.time(orderTimeFrom),
              $options.filters.time(orderTimeTo),
            ])
          }}
        </TAlert>
      </div>
    </v-form>

    <OrderChangedDialog
      v-model="orderChangesDialog"
      :cart="cart"
      :changes="orderChanges"
      :loading="saving"
      :total-price="totalPrice"
      @cancel="orderChangesDialog = false"
      @submit="onSubmit"
    />
  </ServiceViewPage>
</template>

<script>
import dayjs from 'dayjs';
import { actions, getters, state } from '@/store/store';
import { changeType } from '@/constants/changes';
import { resetDataMixin } from '@/mixins/forms';
import { coffeeBreakWorkAndDeliveryMixin } from '@/mixins/services/coffee-break';
import ServiceViewPage from '@/views/services/views/ServiceViewPage';
import ProductCatalog from '@/components/product/ProductCatalog.vue';
import TotalPrice from '@/components/order/TotalPrice';
import OrderChangedDialog from '@/components/order/OrderChangedDialog';
import TAlert from '@/components/ui/TAlert';
import OrderDateInput from '@/components/order/OrderDateInput';
import OrderTimeInput from '@/components/order/OrderTimeInput';
import EmailField from '@/components/ui/EmailField.vue';
import PhoneField from '@/components/ui/PhoneField.vue';
import AcceptTerms from '@/components/terms/AcceptTerms.vue';

export default {
  name: 'CoffeeBreakForm',

  components: {
    AcceptTerms,
    PhoneField,
    EmailField,
    OrderTimeInput,
    OrderDateInput,
    TAlert,
    OrderChangedDialog,
    TotalPrice,
    ProductCatalog,
    ServiceViewPage,
  },

  mixins: [coffeeBreakWorkAndDeliveryMixin, resetDataMixin],

  data() {
    return {
      cart: [
        // { ...product, qty: 0 }
      ],
      saving: false,
      form: {
        date: null,
        time: null,
        pickupFromReception: null,
        needToPack: false,
        servedOnTable: false,
        deliveryPlace: null,
        name: undefined,
        phone: undefined,
        email: undefined,
        comment: null,
      },
      orderChanges: [],
      orderChangesDialog: false,
      error: false,
    };
  },

  computed: {
    showCatalog() {
      return getters.showCatalog();
    },

    service() {
      return state.selectedService;
    },

    userHash() {
      return state.userHash;
    },

    products() {
      return state.cbProducts;
    },

    categories() {
      const categoryIds = this.products.map(x => x.categoryId);

      return state.cbCategories.filter(x => categoryIds.includes(x.id));
    },

    totalPrice() {
      return this.cart.reduce((total, x) => total + x.price * x.qty, 0);
    },

    totalPriceRules() {
      if (!this.service.minPrice) {
        return [];
      }

      return [
        v =>
          v >= this.service.minPrice ||
          this.$t('Минимальная сумма заказа {0}', [this.$n(this.service.minPrice, 'currency')]),
      ];
    },
  },

  watch: {
    'form.pickupFromReception'(val) {
      if (!val) {
        this.form.needToPack = false;
        this.form.servedOnTable = false;
      }
    },

    'form.needToPack'(val) {
      if (val) {
        this.form.servedOnTable = false;
      }
    },

    'form.servedOnTable'(val) {
      if (val) {
        this.form.needToPack = false;
      }
    },
  },

  async mounted() {
    if (this.hasPickup && (!this.hasDelivery || !this.isDeliveryTime)) {
      this.form.pickupFromReception = true;
    }
    if (!this.hasPickup && this.hasDelivery && this.isDeliveryTime) {
      this.form.pickupFromReception = false;
    }

    const promises = [
      actions.fetchCbCategories(),
      actions.fetchCbProducts({ serviceId: this.service.id }),
    ];

    if (this.service.workBeginTime) {
      promises.push(actions.fetchWorkTime({ serviceId: this.service.id, date: this.date }));
    }

    await Promise.all(promises);
  },

  methods: {
    async checkChanges() {
      await actions.fetchCbProducts({ serviceId: this.service.id });

      const newCart = [...this.cart];

      this.orderChanges = [];
      this.cart.forEach(item => {
        const product = this.products.find(x => x.id === item.id);

        if (!product) {
          this.orderChanges.push({ type: changeType.REMOVE, name: item.name });
          newCart.splice(newCart.indexOf(item), 1);
          return;
        }

        if (item.price !== product.price) {
          this.orderChanges.push({ type: changeType.PRICE });
        }

        if (!product.unlimitedStocks) {
          const availableQty = product.stockQuantity - product.reservationQuantity;
          if (item.qty > availableQty) {
            this.orderChanges.push({ type: changeType.QTY });
            item.qty = availableQty;
          }
        }
      });

      return this.orderChanges.length === 0;
    },

    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      const checked = await this.checkChanges();
      if (!checked) {
        this.resetData();
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });

        return alert('Произошла ошибка. Повторите попытку!');
      }

      this.orderChangesDialog = false;

      const items = [];
      this.cart.forEach(x => items.push({ productId: x.id, count: x.qty }));

      const readyTime =
        this.form.time && this.form.date
          ? dayjs.tz(`${this.form.date} ${this.form.time}`).unix()
          : null;

      const payload = {
        serviceId: this.service.id,
        readyTime: readyTime ?? null,
        pickupFromReception: this.form.pickupFromReception,
        needToPack: this.form.needToPack,
        servedOnTable: this.form.servedOnTable,
        deliveryPlace: this.form.pickupFromReception
          ? this.service.deliveryPickupTitle
          : this.form.deliveryPlace,
        items: items,
        comment: this.form.comment,
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        source: 'widget',
      };

      try {
        const serviceTicket = await actions.createOrder(payload);
        await actions.handlePayment({ serviceTicket });
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
