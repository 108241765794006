<template>
  <div>
    <Wrapper class="mb-6">
      <h1 class="text-h1 flex-grow-1">{{ event.name }}</h1>
    </Wrapper>
    <Wrapper>
      <MediaCarousel :images="event.images" />
    </Wrapper>
    <Wrapper class="mt-6">
      <Tag v-if="event.fixedOnTopPosition" class="mr-1" color="primary" text-color="white">
        {{ $t('Важно') }}
      </Tag>
      <Tag
        v-for="tag in event.tags"
        :key="tag"
        class="mr-1"
        @click.prevent="$emit('click:tag', tag)"
      >
        {{ tag }}
      </Tag>
    </Wrapper>
    <Wrapper class="mt-8">
      <EditorContent :content="event.description" />
    </Wrapper>
  </div>
</template>

<script>
import { EventTicketStatus } from '@/constants/event';
import Wrapper from '@/components/ui/Wrapper';
import MediaCarousel from '@/components/ui/MediaCarousel';
import EditorContent from '@/components/ui/EditorContent';
import Tag from '@/components/ui/Tag.vue';

export default {
  name: 'EventContent',

  components: {
    Tag,
    EditorContent,
    MediaCarousel,
    Wrapper,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
    liked: Boolean,
    subscribed: Boolean,
    showAdminInfo: {
      type: Boolean,
      default: false,
    },
    ticket: Object,
  },

  computed: {
    hasActiveTicket() {
      return this.ticket && this.ticket.status !== EventTicketStatus.CANCELED;
    },
  },
};
</script>
