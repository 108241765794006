<template>
  <div id="tpw">
    <v-app v-if="locationId">
      <v-main v-if="servicesLoaded">
        <OrderInfo v-if="serviceTicket" />

        <ServiceCatalog
          v-else-if="!selectedService"
          :category-id="selectedCategory && selectedCategory.id"
          @back="goBack"
        />

        <template v-else>
          <BookingForm
            v-if="[ServiceType.BOOKING, ServiceType.MASSAGE].includes(selectedService.type)"
            @back="goBack"
          />
          <CoffeeBreakForm
            v-if="selectedService.type === ServiceType.COFFEE_BREAK"
            @back="goBack"
          />
          <CoworkingForm v-if="selectedService.type === ServiceType.COWORKING" @back="goBack" />
          <HotelForm v-if="selectedService.type === ServiceType.HOTEL" @back="goBack" />
          <SimpleServiceView
            v-if="[ServiceType.COMMERCIAL, ServiceType.RENT_OFFICE].includes(selectedService.type)"
            @back="goBack"
          />
        </template>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, state } from '@/store/store';
import { ServiceType } from '@/constants/service';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import BookingForm from '@/views/services/BookingForm.vue';
import OrderInfo from '@/views/services/OrderInfo.vue';
import ServiceCatalog from '@/views/services/ServiceCatalog.vue';
import SimpleServiceView from '@/views/services/SimpleServiceView.vue';
import CoworkingForm from '@/views/services/CoworkingForm.vue';
import HotelForm from '@/views/services/HotelForm.vue';
import CoffeeBreakForm from '@/views/services/CoffeeBreakForm.vue';

export default {
  name: 'App',

  components: {
    CoffeeBreakForm,
    HotelForm,
    CoworkingForm,
    SimpleServiceView,
    ServiceCatalog,
    OrderInfo,
    BookingForm,
  },

  mixins: [commonPropsMixin, themeMixin],

  provide() {
    return {
      locationId: this.locationId,
    };
  },

  props: {
    serviceId: [Number, String],
  },

  computed: {
    ServiceType() {
      return ServiceType;
    },

    location() {
      return state.location;
    },

    servicesLoaded() {
      return state.servicesLoaded;
    },

    serviceTicket() {
      return state.serviceTicket;
    },

    selectedCategory() {
      return state.selectedCategory;
    },

    selectedService() {
      return state.selectedService;
    },
  },

  watch: {
    selectedCategory() {
      this.scrollToTop();
    },

    selectedService() {
      this.scrollToTop();
    },
  },

  created() {
    actions.initData({
      locationId: this.locationId,
      serviceId: this.serviceId,
      userHash: this.userHash,
    });
  },

  mounted() {
    actions.fetchLocationInfo();
    actions.fetchServicesCatalog();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    goBack() {
      state.selectedCategory = null;
      state.selectedService = null;
      state.token = null;
      this.scrollToTop();
    },
  },
};
</script>
