<template>
  <div>
    <v-text-field
      ref="text"
      :value="displayText"
      :label="label"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      :readonly="!autocomplete || !isMenuActive"
      :rules="patchRules"
      autocomplete="off"
      append-icon="$dropdown"
      v-bind="$attrs"
      @input="search = $event"
      @click:clear="onClear"
    />
    <v-menu ref="menu" v-model="isMenuActive" :activator="activator" bottom v-bind="menuProps">
      <v-sheet>
        <v-treeview
          ref="tree"
          :value="treeValue"
          :items="finalItems"
          :activatable="!multiple"
          :active.sync="active"
          :color="null"
          dense
          item-key="id"
          item-text="name"
          :selectable="multiple"
          :selection-type="selectionType"
          open-all
          :search="search"
          :return-object="returnObject"
          @input="$emit('update:modelValue', $event)"
        />
        <v-list v-if="!hasSearchResult" dense>
          <v-list-item>{{ $t('Данные отсутствуют') }}</v-list-item>
        </v-list>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import { listToTree, treeToList } from '@/utils/tree';

const defaultMenuProps = {
  closeOnClick: true,
  closeOnContentClick: false,
  disableKeys: false,
  openOnClick: true,
  maxHeight: 420,
};

export default {
  name: 'TreeSelect',

  inheritAttrs: false,

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    autocomplete: Boolean,
    clearable: Boolean,
    disabled: {
      type: Boolean,
      default: undefined,
    },
    // flat array, not tree
    items: {
      type: Array,
    },
    treeItems: {
      type: Array,
    },
    label: String,
    modelValue: {
      type: [Array, Number],
    },
    placeholder: String,
    selectionType: {
      type: String,
      default: 'independent',
    },
    returnObject: Boolean,
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: Array,
  },

  data() {
    let menuProps = defaultMenuProps;
    if (this.autocomplete) {
      menuProps = {
        ...defaultMenuProps,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
        // closeOnClick: false,
        disableKeys: true,
        nudgeBottom: 1,
        // openOnClick: false,
      };
    }

    return {
      activator: null,
      active: [],
      isFocused: false,
      isMenuActive: false,
      menuProps: menuProps,
      search: '',
      hasSearchResult: true,
    };
  },

  computed: {
    finalItems() {
      return this.items ? listToTree(this.items) : this.treeItems;
    },

    flatItems() {
      return this.items ? this.items : treeToList(this.treeItems);
    },

    treeValue() {
      return this.multiple ? this.modelValue : [];
    },

    patchRules() {
      return this.rules && this.rules.map(x => () => x.call(x, this.modelValue));
    },

    displayText() {
      if (this.isMenuActive) {
        return this.search;
      }

      const values =
        this.modelValue && !Array.isArray(this.modelValue) ? [this.modelValue] : this.modelValue;
      const count = values?.length;

      if (!count) {
        return null;
      }

      if (count === 1) {
        if (this.returnObject) {
          return values[0].name ?? '-';
        }

        return this.flatItems.length
          ? this.flatItems.find(x => x.id === values[0])?.name ?? '-'
          : '';
      }

      return this.$t('Выбрано {0}', [count]);
    },
  },

  watch: {
    active() {
      this.isMenuActive = false;
      this.$emit(
        'update:modelValue',
        this.active && this.active.length > 0 ? this.active[0] : null
      );
    },

    modelValue(val) {
      if (!this.multiple) {
        this.active = val !== null ? [val] : [];
      }
    },

    isMenuActive(val) {
      if (!val) {
        this.search = '';
      }
    },

    search() {
      this.updateMenuDimensions();
      this.$nextTick(() => {
        if (this.search && this.$refs.tree) {
          this.hasSearchResult = this.$refs.tree.$el.hasChildNodes();
        }
      });
    },
  },

  async mounted() {
    this.activator = this.$refs.text.$refs['input-slot'];
  },

  methods: {
    open() {
      this.$refs.tree.updateAll(true);
    },

    onClear() {
      this.search = '';
      if (!this.isMenuActive) {
        this.$emit('update:modelValue', this.multiple ? [] : null);
      }
    },

    updateMenuDimensions() {
      this.isMenuActive && this.$refs.menu && this.$refs.menu.updateDimensions();
    },
  },
};
</script>
