<template>
  <PaginatedListContainer
    ref="list"
    v-slot="{ loading }"
    :filter="filter"
    :pagination.sync="pagination"
    :fetch-items="fetchItems"
  >
    <v-card rounded="lg" elevation="4" class="mb-4">
      <v-form :disabled="loading" class="pa-4 px-6">
        <div class="d-flex align-center">
          <v-row class="row-filter">
            <v-col cols="3">
              <FilterField>
                <DatePickerPeriod
                  :start.sync="filter.periodStart"
                  :end.sync="filter.periodEnd"
                  :min="minDate"
                  :label="$t('Дата проведения')"
                />
              </FilterField>
            </v-col>
            <v-col cols="3">
              <FilterField>
                <v-select
                  v-model="filter.category"
                  class="one-line"
                  :label="$t('Категория мероприятий')"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  multiple
                />
              </FilterField>
            </v-col>
            <v-col cols="3">
              <FilterField>
                <v-text-field v-model="filter.search" :label="$t('Название')" />
              </FilterField>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <Tag v-if="filter.tag" small close @click:close="removeTag(filter.tag)">
                {{ filter.tag }}
              </Tag>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>

    <div class="mt-4">
      <v-data-iterator
        :items="items"
        :loading="loading"
        item-key="id"
        disable-pagination
        hide-default-footer
      >
        <template #default="props">
          <v-row class="ev-row">
            <v-col v-for="item in props.items" :key="item.id" cols="12" md="6" lg="4" xl="3">
              <EventCard
                class="ev-card"
                :event="item"
                :tariff="tariff"
                @click:tag="onClickTag"
                @click="onClick(item)"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>

      <div v-if="meta.pageCount > 1" class="v-data-footer justify-start pt-6">
        <Pagination
          :key="meta.pageCount"
          v-model="pagination.page"
          :disabled="loading"
          :length="meta.pageCount"
        />
      </div>
    </div>
  </PaginatedListContainer>
</template>

<script>
import dayjs from 'dayjs';
import { actions, state } from '@/store/eventStore';
import { makeFilter, makePagination } from '@/utils/pagination';
import { EventTicketTariff } from '@/constants/event';
import FilterField from '@/components/ui/filter/FilterField.vue';
import DatePickerPeriod from '@/components/ui/DatePickerPeriod.vue';
import Pagination from '@/components/ui/Pagination.vue';
import PaginatedListContainer from '@/components/common/PaginatedListContainer.vue';
import EventCard from '@/components/event/EventCard.vue';
import Tag from '@/components/ui/Tag.vue';

const filterConfig = {
  search: String,
  periodStart: Number,
  periodEnd: Number,
  category: [Number],
  tag: String,
};

export default {
  name: 'EventList',

  components: {
    Tag,
    EventCard,
    PaginatedListContainer,
    Pagination,
    DatePickerPeriod,
    FilterField,
  },

  data() {
    return {
      filter: {},
      pagination: {},
    };
  },

  computed: {
    items() {
      return state.events;
    },

    meta() {
      return state.meta;
    },

    categories() {
      return state.categories;
    },

    tariff() {
      return EventTicketTariff.GUEST;
    },

    minDate() {
      return dayjs.tz().format('YYYY-MM-DD');
    },
  },

  created() {
    this.filter = makeFilter(state.params, filterConfig);
    this.pagination = makePagination(state.params);
  },

  mounted() {
    if (!this.categories.length) {
      actions.fetchCategories();
    }
  },

  methods: {
    fetchItems(params) {
      return actions.fetchEvents({
        ...params,
        sort: 'fixed_on_top_position,start_at',
        pageSize: 9,
      });
    },

    onClick(item) {
      state.currentEvent = item;
    },

    onClickTag(tag) {
      this.$set(this.filter, 'tag', tag);
    },

    removeTag() {
      this.$delete(this.filter, 'tag');
    },
  },
};
</script>
