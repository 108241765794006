let locationId;
let userHash;

function getQueryString(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}

function request(options) {
  const method = options.method || 'GET';

  const headers = options.headers || {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-T-Park-Location': locationId,
  };

  if (userHash) {
    headers['X-T-Park-Ext-Hash'] = userHash;
  }

  let qs = '';
  if (options.params && Object.keys(options.params).length > 0) {
    qs = '?' + getQueryString(options.params);
  }

  let body;
  if (options.data) {
    body = JSON.stringify(options.data);
  }

  const url = options.url + qs;

  return fetch(url, { method, headers, body }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }

    return response.json();
  });
}

export default {
  setLocationId: id => (locationId = id),
  setUserHash: hash => (userHash = hash),
  get: (url, config) => request(Object.assign({ method: 'GET', url }, config)),
  post: (url, data, config) => request(Object.assign({ method: 'POST', url, data }, config)),
  patch: (url, data, config) => request(Object.assign({ method: 'PATCH', url, data }, config)),
  put: (url, data, config) => request(Object.assign({ method: 'PUT', url, data }, config)),
  delete: (url, config) => request(Object.assign({ method: 'DELETE', url }, config)),
};
